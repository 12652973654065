// YourComponent.js
import React, { useState, useEffect } from "react";
import { getLocalStorageData } from "utils/utilFunctions";
import { Select, MenuItem, FormControl } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Icons } from "shared/Icons/Icons";

import { getdropdownValue } from "redux/auth/actions";
function NFTDropdown() {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState("isShowAll");
  const dispatch = useDispatch();

  useEffect(() => {
    const savedOption = getLocalStorageData("selectedOption") || "isShowAll";
    if (savedOption) {
      setSelectedOption(savedOption);
    }
    dispatch(getdropdownValue({ isShowAll: savedOption }));
  }, [selectedOption]);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    localStorage.setItem("selectedOption", selectedValue);
  };

  return (
    <>
      <FormControl
        sx={{
          background: "transparent",
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
          fontWeight: "500",
          borderRadius: "0px"
        }}
      >
        <Select
          className="counterFilter"
          sx={{
            color: "#757575",
            fontWeight: "500",

            "& .MuiSelect-icon": {
              right: "8px",
              top: "34%"
            },
            "& .MuiSelect-select, & .MuiSelect-select.MuiSelect-standard, & .MuiInputBase-input, & .MuiInput-input": {
              display: "flex"
            }
          }}
          variant="standard"
          displayEmpty
          value={selectedOption}
          onChange={handleOptionChange}
          defaultValue="onlySelected"
          disableUnderline={true}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={CustomSelectIcon}
          MenuProps={{
            PaperProps: {
              style: {
                background: "#2D3337",
                borderRadius: "0px"
              }
            }
          }}
        >
          <MenuItem value="isShowAll">
            <span className="isShowAll">{Icons?.showAll}</span>
            <span className="HeaderFonts" style={{ color: "white" }}>
              {" "}
              Show all items
            </span>
          </MenuItem>
          <MenuItem value="onlySelected">
            <span className="isShowAll">{Icons?.availableCountries}</span>
            <span className="HeaderFonts" style={{ color: "white" }}>
              {" "}
              Available in my country
            </span>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
const CustomSelectIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 9 6" fill="none" {...props}>
      <path
        d="M1 1.24023L4.53 4.76023L8.06 1.24023"
        stroke="#2F53FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NFTDropdown;
