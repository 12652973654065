import PropTypes from "prop-types";
import { useState } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { WRONG_CHAIN_MESSAGES, CHAIN_IDS, RPC_URLS, NETWORKS_INFO } from "utils/constants";

import { Avatar, Box, ButtonBase, Tooltip, Badge, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// project imports
import LogoSection from "../LogoSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import { Icons } from "../../../shared/Icons/Icons";
import { ethers, utils } from "ethers";
import { setWallet } from "redux/auth/actions";
import { SNACKBAR_OPEN } from "store/actions";
import Erc20 from "../../../contractAbi/Erc20.json";
import BLOCKCHAIN from "../../../../src/constants";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { IconMenu2 } from "@tabler/icons";
import { styled as stylee } from "@mui/system";
import { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import NotificationMenu from "layout/UserLayout/header/NotificationMenu";
import { notificationCountIcon } from "redux/marketplace/actions";
import SocketSvg from "layout/UserLayout/header/SocketSvg";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const SquareIconButton = stylee(IconButton)(({ theme }) => ({
  width: "38px",
  height: "38px",
  borderRadius: "8px",
  background: theme.palette.action.hover
}));

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationsIconss = useSelector((state) => state.marketplaceReducer.notificationIconCount);

  const [walletAddress, setWalletAddress] = useState();
  const [balanceValue, setbalanceValue] = useState();
  const [maticValue, setMaticValue] = useState();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth?.token);
  const userWalletAddress = useSelector((state) => state.auth?.walletAddress);

  const [notificationDrop, setNotificationDrop] = useState(null);
  const isNotificationOpen = Boolean(notificationDrop);

  useEffect(() => {
    (async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const chainId = await provider.getNetwork();

      // Check if user is logged in
      // Metamask is connected to browser
      // Connected network is amoy
      if (token && address && chainId?.chainId?.toString() === CHAIN_IDS.POLYGON_CHAIN_ID) {
        handleConnect();
      }
    })();
  }, [token]);

  const promptSwitchNetwork = () => {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: NETWORKS_INFO.chainId }]
      })
      .then(() => {
        setTimeout(() => {
          handleConnect();
        }, 1000);
      })
      .catch((error) => {
        if (error.code === 4902) {
          // Chain not added error code
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: NETWORKS_INFO.chainId,
                  chainName: NETWORKS_INFO.chainName,
                  nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18
                  },
                  rpcUrls: [NETWORKS_INFO.rpcUrls],
                  blockExplorerUrls: [NETWORKS_INFO.blockExplorerUrl]
                }
              ]
            })
            .then(() => {
              console.log("Added and switched to Polygon mainnet");
              setTimeout(() => {
                handleConnect();
              }, 1000);
            })
            .catch((addError) => {
              console.error("Failed to add and switch to Polygon mainnet:", addError.message);
            });
        } else {
          console.error("Failed to switch to Polygon mainnet:", error.message);
        }
      });
  };

  const handleConnect = async () => {
    if (!window.ethereum) {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: "You need to install metamask to proceed.",
        variant: "alert",
        alertSeverity: "info"
      });
      toast.error("You need to install metamask to proceed.");
    } else if (window?.ethereum?.networkVersion !== CHAIN_IDS.POLYGON_CHAIN_ID) {
      promptSwitchNetwork();

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: `Please switch to ${NETWORKS_INFO.chainName} network from your metamask`,
        variant: "alert",
        alertSeverity: "info"
      });
      // toast.error(WRONG_CHAIN_MESSAGES.POLYGON);
      setWalletAddress();
      dispatch(setWallet(null));
    } else {
      const response = await window?.ethereum?.request({ method: "eth_requestAccounts" });
      const addressed = utils?.getAddress(response[0]);
      setWalletAddress(addressed);
      dispatch(setWallet(addressed));

      const maticprovider = ethers.getDefaultProvider(RPC_URLS.POLYGON_RPC_URL);
      const maticbalance = await maticprovider?.getBalance(response[0]);
      const maticvalue = ethers.utils?.formatEther(maticbalance);
      setMaticValue(Number(maticvalue));
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      let erc20Address = BLOCKCHAIN.USDC_ERC20;

      const token = new ethers.Contract(erc20Address, Erc20.abi, signer);
      let balance = await token.balanceOf(address);
      let value = utils.formatUnits(balance, 6);
      setbalanceValue(Number(value));
    }
  };
  const CustomTypography = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#44494c",
      color: "white"
    }
  }))(Tooltip);

  useEffect(() => {
    const handleAccountsChanged = () => {
      handleConnect();
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.off("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);

  useEffect(() => {
    if (token && userWalletAddress) {
      handleConnect();
    }
  }, [token, userWalletAddress]);
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          backgroundColor: `${theme.palette.mode === "dark" ? "#181C1F" : "white"}`,
          height: "4em",
          paddingTop: "1em",
          width: 200,
          marginLeft: "2%",
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto"
          }
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title> Galileo Dashboard</title>
        </Helmet>
        <Box component="span" sx={{ display: { xs: "none", md: "block" }, flexGrow: 1, marginTop: "1rem" }}>
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            display: { md: "block", lg: "none", xs: "block", sm: "block" },
            marginTop: { md: "0", lg: "0", xs: "-15px", sm: "-15px" },
            borderRadius: "",
            overflow: "hidden"
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.light,
              color: "#2F5AFF",
              "&:hover": {
                background: "#2F5AFF",
                color: "#FFF"
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {(user?.role == "Sub Admin" || "Brand Admin" || "Super Admin") && user?.role != "User" && user != null && (
        <Box>
          <SquareIconButton
            onClick={() => {
              navigate("/home");
            }}
            size="large"
            aria-label=""
            color="inherit"
            sx={{ mx: 0.5 }}
          >
            <Badge>{Icons.marketPlaceTopBar}</Badge>
          </SquareIconButton>
        </Box>
      )}

      <Box
        sx={{
          display: { xs: "none", sm: "flex", md: "flex" },
          color: `${theme.palette.mode === "dark" ? "#ffffff" : "#000000"}`,
          background: theme.palette.action.hover,
          paddingY: "8px",
          borderRadius: "8px",
          mx: 1
        }}
      >
        {user?.walletAddress && walletAddress ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              px: 2,
              mr: 0.5
            }}
          >
            {walletAddress !== user.walletAddress ? (
              <CustomTypography title="This wallet is not registered" placement="top" arrow>
                <Box sx={{ display: "flex", position: "relative" }}>
                  {Icons.wallet}
                  <Box sx={{ position: "absolute", right: "2%", top: "-4%" }}>{Icons.evenodd}</Box>
                </Box>
              </CustomTypography>
            ) : (
              Icons.wallet
            )}
            {/* <img src={WalletIcon} alt="Notificationicon" style={{ color: '#4dabf5', width: '24px', height: '24px' }} /> */}
            <Typography sx={{ ml: 1 }} className="HeaderFonts">
              {balanceValue && (
                <>
                  {balanceValue.toFixed(2)} <Box as="span">USDC</Box>
                </>
              )}
            </Typography>
            <Box sx={{ borderRight: "1px solid  #575c5f", height: "80%", marginX: "5px" }}></Box>
            <Typography className="HeaderFonts">
              {/* {balanceValue && balanceValue.slice(0, 5)} <Box as="span">USDT</Box> */}
              {maticValue && maticValue.toFixed(2)} <Box as="span">MATIC</Box>
            </Typography>
            <Box sx={{ borderRight: "1px solid  #575c5f", height: "80%", marginX: "5px" }}></Box>
            <Typography className="HeaderFonts">
              {walletAddress && walletAddress.slice(0, 5) + "..." + walletAddress.slice(39, 42)}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              px: 2,
              mr: 1
            }}
          >
            {Icons.wallet}
            <Typography onClick={handleConnect} sx={{ px: 1, cursor: "pointer" }} className="HeaderFonts">
              Connect Wallet
            </Typography>
          </Box>
        )}
      </Box>
      <SquareIconButton
        size="large"
        aria-label=""
        color="inherit"
        sx={{ mx: 0.5 }}
        onClick={(e) => {
          setNotificationDrop(e.currentTarget);
          if (notificationsIconss) {
            dispatch(notificationCountIcon(false));
          }
        }}
      >
        <Badge>{notificationsIconss ? Icons.notification : Icons.notificationCount}</Badge>
      </SquareIconButton>
      <SquareIconButton
        size="large"
        aria-label="notification"
        color="inherit"
        sx={{ mx: 0.5, padding: "8px" }}
        onClick={() => {
          window.open("https://galileonetwork.zohodesk.eu/portal/en/kb", "_blank");
        }}
      >
        <Tooltip title="Support Center" placement="top" arrow>
          {Icons.supportIcon}
        </Tooltip>
      </SquareIconButton>
      <SocketSvg />
      <ProfileSection />

      {/* mobile header */}
      <NotificationMenu
        notificationDrop={notificationDrop}
        setNotificationDrop={setNotificationDrop}
        isNotificationOpen={isNotificationOpen}
      />
      <Box sx={{ display: { xs: "none", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
