import React from "react";
import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// project imports
// import * as serviceWorker from 'serviceWorker';
import App from "App";
import { store, persistor } from "store";

// style + assets
import "assets/scss/style.scss";
import config from "config";
import { GoogleOAuthProvider } from "@react-oauth/google";

// ==============================|| REACT DOM RENDER  ||============================== //

const AppDom = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        {/* <ConnectedRouter history={history}> */}
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={config.basename}>
            <App />
          </BrowserRouter>
        </PersistGate>
        {/* </ConnectedRouter> */}
      </Provider>
    </GoogleOAuthProvider>
  );
};

const root = document.getElementById("root");
const rootInstance = createRoot(root);
rootInstance.render(<AppDom />);
